var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataLoaded)?_c('div',{staticClass:"contact page-wrapper"},[_c('div',{staticClass:"\n            contact-form-section\n            text-white\n            bg-primary\n            pt-6\n            pb-7\n            pt-xl-7\n            pb-xl-9\n            position-relative\n        "},[_c('ShapePicture',{staticClass:"shape-01",attrs:{"name":"contact/Shape-01"}}),_c('ShapePicture',{staticClass:"shape-02",attrs:{"name":"contact/Shape-02"}}),_c('div',{staticClass:"container-shorter container-fluid"},[_c('div',{staticClass:"row text-center"},[_c('h1',{staticClass:"col-12"},[_vm._v(_vm._s(_vm.object.acf.title))]),_c('p',{staticClass:"col-12 text-semibold text-bigger"},[_vm._v(" "+_vm._s(_vm.object.acf.subtitle)+" ")])]),_c('div',{staticClass:"row mt-5 mt-xl-6"},[_c('div',{staticClass:"col-12 col-md-7"},[_c('h2',{staticClass:"text font-weight-bold mb-4 mb-md-5 d-none d-md-block"},[_vm._v(" Get in touch: ")]),_vm._l((_vm.object.acf
                                .company_emails),function(company_email){return _c('div',{key:company_email.email,staticClass:"mb-5"},[_c('span',{staticClass:"d-block mb-1 text font-weight-semibold"},[_vm._v(" "+_vm._s(company_email.label)+": ")]),_c('a',{staticClass:"link-white h2 font-weight-light",attrs:{"href":'mailto:' + company_email.email}},[_vm._v(_vm._s(company_email.email))])])}),_c('span',{staticClass:"d-block mb-3 text font-weight-semibold"},[_vm._v(" Social Media: ")]),_c('social-icons',{staticClass:"link-white text-semibold mb-6 mt-2",class:['xs', 'sm', 'md', 'lg'].includes(_vm.$mq)
                                    ? 'h4'
                                    : 'text'})],2),_c('div',{staticClass:"col-12 col-md pl-md-6"},[_c('h2',{staticClass:"text font-weight-bold mb-4 mb-md-5 d-none d-md-block"},[_vm._v(" Locations: ")]),_vm._l((_vm.object.acf
                                .company_locations),function(company_location){return _c('div',{key:company_location.name,staticClass:"\n                            mb-5\n                            d-flex\n                            flex-column\n                            justify-content-between\n                            text-center\n                            text-md-left\n                        "},[_c('div',[_c('h3',{staticClass:"h2 mb-1"},[_vm._v(" "+_vm._s(company_location.name)+" ")]),_c('p',{staticClass:"font-weight-light mb-0",class:['xs', 'sm', 'md', 'lg'].includes(
                                            _vm.$mq
                                        )
                                            ? ''
                                            : 'text',domProps:{"innerHTML":_vm._s(company_location.address)}})]),_c('a',{staticClass:"link-white",class:['xs', 'sm', 'md', 'lg'].includes(_vm.$mq)
                                        ? ''
                                        : 'text',attrs:{"href":("tel:" + (company_location.phone))}},[_vm._v(_vm._s(company_location.phone))])])})],2)])])],1)]):_vm._e(),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }