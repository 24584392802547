<template>
    <div>
        <div class="contact page-wrapper" v-if="dataLoaded">
            <div
                class="
                contact-form-section
                text-white
                bg-primary
                pt-6
                pb-7
                pt-xl-7
                pb-xl-9
                position-relative
            "
            >
                <ShapePicture class="shape-01" name="contact/Shape-01" />
                <ShapePicture class="shape-02" name="contact/Shape-02" />

                <div class="container-shorter container-fluid">
                    <div class="row text-center">
                        <h1 class="col-12">{{ object.acf.title }}</h1>
                        <p class="col-12 text-semibold text-bigger">
                            {{ object.acf.subtitle }}
                        </p>
                    </div>
                    <div class="row mt-5 mt-xl-6">
                        <div class="col-12 col-md-7">
                            <h2
                                class="text font-weight-bold mb-4 mb-md-5 d-none d-md-block"
                            >
                                Get in touch:
                            </h2>
                            <div
                                class="mb-5"
                                v-for="company_email in object.acf
                                    .company_emails"
                                :key="company_email.email"
                            >
                                <span
                                    class="d-block mb-1 text font-weight-semibold"
                                >
                                    {{ company_email.label }}:
                                </span>
                                <a
                                    :href="'mailto:' + company_email.email"
                                    class="link-white h2 font-weight-light"
                                    >{{ company_email.email }}</a
                                >
                            </div>
                            <span
                                class="d-block mb-3 text font-weight-semibold"
                            >
                                Social Media:
                            </span>
                            <social-icons
                                class="link-white text-semibold mb-6 mt-2"
                                :class="
                                    ['xs', 'sm', 'md', 'lg'].includes($mq)
                                        ? 'h4'
                                        : 'text'
                                "
                            ></social-icons>
                        </div>
                        <div class="col-12 col-md pl-md-6">
                            <h2
                                class="text font-weight-bold mb-4 mb-md-5 d-none d-md-block"
                            >
                                Locations:
                            </h2>
                            <div
                                class="
                                mb-5
                                d-flex
                                flex-column
                                justify-content-between
                                text-center
                                text-md-left
                            "
                                v-for="company_location in object.acf
                                    .company_locations"
                                :key="company_location.name"
                            >
                                <div>
                                    <h3 class="h2 mb-1">
                                        {{ company_location.name }}
                                    </h3>
                                    <p
                                        v-html="company_location.address"
                                        class="font-weight-light mb-0"
                                        :class="
                                            ['xs', 'sm', 'md', 'lg'].includes(
                                                $mq
                                            )
                                                ? ''
                                                : 'text'
                                        "
                                    ></p>
                                </div>
                                <a
                                    class="link-white"
                                    :class="
                                        ['xs', 'sm', 'md', 'lg'].includes($mq)
                                            ? ''
                                            : 'text'
                                    "
                                    :href="`tel:${company_location.phone}`"
                                    >{{ company_location.phone }}</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import seoMixin from "@/mixins/seoMixin";
import ShapePicture from "../components/ShapePicture.vue";
import Footer from "../components/Footer.vue";

export default {
    name: "contact",
    mixins: [seoMixin],
    data() {
        return {
            object: {},
            dataLoaded: false
        };
    },
    components: {
        ShapePicture,
        Footer
    },
    created() {
        const pageApi = "wp-json/wp/v2/pages/6?_fields=id,slug,parent,acf";
        const data = axios.get(pageApi).then(res => {
            this.object = res.data;
            this.setSeo(
                this.object.acf.seo_title,
                this.object.acf.seo_description,
                "https://www.wearealive.com/WAA_default.jpeg",
                "https://www.wearealive.com" + this.$route.fullPath
            );
            this.dataLoaded = true;
            document.dispatchEvent(new Event("custom-render-trigger"));
            setTimeout(() => {
                $("body").css("visibility", "unset");
                this.$store.dispatch("setLoading", false);
            }, loadingDelay);
        });
    }
};
</script>
